//  ================================================
//  Mixins
//  ================================================

//  ------------------------------------------------
//  Media Queries
//  ------------------------------------------------

$mini: 480;
$mobile: 700;
$tablet: 900;
$desktop: 1210;

@mixin mini {
    @media only screen and (max-width: #{$mini}px) {
        @content;
    }
}

@mixin gt-mini {
    @media only screen and (min-width: #{$mini+1}px) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (max-width: #{$mobile}px) {
        @content;
    }
}

@mixin gt-mobile {
    @media only screen and (min-width: #{$mobile+1}px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: #{$tablet}px) {
        @content;
    }
}

@mixin gt-tablet {
    @media only screen and (min-width: #{$tablet+1}px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (max-width: #{$desktop}px) {
        @content;
    }
}

@mixin gt-desktop {
    @media only screen and (min-width: #{$desktop+1}px) {
        @content;
    }
}


// ------------------------------------------------
// Mixins
// ------------------------------------------------

// Shortcuts
@mixin pos($coordinates: null null null null, $position: absolute, $z-index: null) {
    @include position($coordinates, $position, $z-index);
}

@mixin bg($src, $repeat: no-repeat, $pos: null, $color: null, $size: null) {
    @include background($src, $repeat, $pos, $color, $size);
}


// Longforms

@mixin font-face($family, $file, $weight: normal, $style: normal) {
    @font-face {
        font-family: $family;
        src: url('webfonts/#{$file}.eot');
        src: url('webfonts/#{$file}.eot?#iefix') format('embedded-opentype'),
        url('webfonts/#{$file}.woff') format('woff'),
        url('webfonts/#{$file}.ttf') format('truetype');
        font-weight: $weight;
        font-style: $style;
    }
}

@mixin circle($value) {
    width: $value;
    height: $value;
    border-radius: 100%;
}

@mixin background($src, $repeat: no-repeat, $pos: null, $color: null, $size: null) {
    background: {
        @if $color != null { color: $color; }
        image: url(../img/#{$src});
        @if $pos != null { position: $pos; }
        @if $size != null { size: $size; }
        repeat: $repeat;
    }
}

@mixin calc($value) {
    @each $prefix in -webkit-, -moz-, -ms-, -o-, '' {
        width: #{$prefix}calc(#{$value});
    }
}

// Examples:
// @include position(0 0 0 0, fixed);

// center element
// @include position(center);

@mixin position ($coordinates: null null null null, $position: absolute, $z-index: null) {

    position: $position;

    @if ($coordinates == "center") {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } @else if (type-of($coordinates) == list) {

        @if (nth($coordinates, 1) == "center") {

            @if (nth($coordinates, 2) == "top" or nth($coordinates, 2) == "bottom") {
                // horizontal centering
                left: 50%;
                transform: translate(-50%, 0);
            } @else {
                // vertical centering
                top: 50%;
                transform: translate(0, -50%);
            }

            @if (length($coordinates) == 3) {
                #{nth($coordinates, 2)}: nth($coordinates, 3);
            } @else {
                #{nth($coordinates, 2)}: 0;
            }

        } @else {
            $top: nth($coordinates, 1);
            $right: nth($coordinates, 2);
            $bottom: nth($coordinates, 3);
            $left: nth($coordinates, 4);

            @if ($top and $top == auto) or (type-of($top) == number) {
                top: $top;
            }

            @if ($right and $right == auto) or (type-of($right) == number) {
                right: $right;
            }

            @if ($bottom and $bottom == auto) or (type-of($bottom) == number) {
                bottom: $bottom;
            }

            @if ($left and $left == auto) or (type-of($left) == number) {
                left: $left;
            }
        }
    }

    @if ($z-index != null) {
        z-index: $z-index;
    }
}

// Example Multiple:  @include tn((opacity, visibility), (.5s, 0s), (0, .5s), (ease-out, linear))
// Example Simple:    @include tn(opacity, .5s, 0, linear);
@mixin tn($property: all, $duration: 0.3s, $delay: 0s, $function: linear){
    @if (type-of($property) == list) {
        $output: null;
        @each $current-property in $property {
            $i: index($property, $current-property);
            $current-duration: null;
            $current-delay: null;
            $current-function: null;

            @if (type-of($duration) == list) {
                $current-duration: nth($duration, $i);
            } @else {
                $current-duration: $duration;
            }

            @if (type-of($delay) == list) {
                $current-delay: nth($delay, $i);
            } @else {
                $current-delay: $delay;
            }

            @if (type-of($function) == list) {
                $current-function: nth($function, $i);
            } @else {
                $current-function: $function;
            }
            @if ($i == 1) {
                $output: $current-property $current-duration $current-delay $current-function;
            } @else {
                $output: $output, $current-property $current-duration $current-delay $current-function;
            }
        }
        transition: $output;
    } @else {
        transition: $property $duration $delay $function;
    }
}

@mixin rgba($color, $opacity) {
    background: $color;
    background: rgba($color, $opacity);
}



//  ------------------------------------------------
//  Extends
//  ------------------------------------------------

// Usage: @extend %reset;
%reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

// Usage: @extend %clearfix;
%clearfix {
    *zoom: 1;

    &:before, &:after { content: ""; display: table; }
    &:after { clear: both; }
}

// Usage: @extend %clearfix;
%backface {
    backface-visibility: hidden;
}
