//  ================================================
//  General
//  ================================================

body {
    padding-top: 98px;
}

*, *:before, *:after {
    box-sizing: border-box;
}

a {
    color: #333;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

p {
    margin: 0 0 40px;

    &.intro-text {
        font-family: Avenir, sans-serif;
        font-size: 22px;
        line-height: 32px;
    }

    &:last-child {
        margin-bottom: 0
    }
}

address {
    font-style: normal;
}


//  ================================================
//  Forms
//  ================================================

// Input iOS reset
input[type=text], textarea {
    border-radius: 0;
    -webkit-appearance: none;
}

// Placeholder
::placeholder { color: #ccc }


//  ================================================
//  Overlay
//  ================================================

@if ($has-overlay) {

    .Overlay {
        @include rgba(#000, 0.5);
        @include position(0 0 0 0, fixed);
        @include tn((opacity, visibility), (.3s, 0s), (0s, .3s));
        z-index: $z10;
        visibility: hidden;
        opacity: 0;
        height: 100%;
        width: 100%;
        text-align: center;

        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -0.25em;
        }

        &.is-open {
            @include tn(opacity, .3s);
            visibility: visible;
            opacity: 1;
        }

        .Overlay__content {
            @include tn(opacity, .3s);
            background: #fff;
            padding: 20px;
            opacity: 0;
            width: 50%;
            margin: 0 auto;
            display: inline-block;
            vertical-align: middle;
            text-align: left;

            &.is-active {
                opacity: 1;
            }
        }
    }

}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a, a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links for images, or javascript/internal links
     */

     .ir a:after,
     a[href^="javascript:"]:after,
     a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}