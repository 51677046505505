//  ================================================
//  Header
//  ================================================

header.main {
    @include pos(0 0 null 0, fixed, $z10);
    background: #fff;
    padding-top: 35px;
    padding-bottom: 26px;
    box-shadow: 1px 0px 6px 0px rgba(0, 0, 0, 0.2);

    @include mobile {
        padding-top: 10px;
        text-align: center;
        padding-bottom: 8px;
    }

    h1 {
        margin-bottom: 0;
    }

    .logo {
        @include bg('logo.png');
        display: inline-block;
        width: 171px;
        height: 37px;
        text-indent: -9999px;

        .svg & {
            @include bg('logo.svg');
        }
    }

    nav {
        @include gt-mobile {
            @include pos(null 0 -10px null);
        }

        @include mobile {
            padding-top: 10px;
            text-align: center;
        }

        a {
            font-family: Avenir, sans-serif;
            font-size: 18px;
            line-height: 32px;
            text-transform: uppercase;
            @include gt-mobile {
                margin-left: 26px;
            }

            @include mobile {
                margin: 0 10px;
            }

            &.is-active {
                color: $highlight;
            }

            &:hover {
                color: $highlight;
                @include tn;
            }

            }
        }
    }
