//  ================================================
//  Variables
//  ================================================

//  ------------------------------------------------
//  Colors
//  ------------------------------------------------

$body: #363636;
$highlight: #e54626;


//  ------------------------------------------------
//  z-index
//  ------------------------------------------------

$z1: 100;
$z2: 200;
$z3: 300;
$z4: 400;
$z5: 500;
$z6: 600;
$z7: 700;
$z8: 800;
$z9: 900;
$z10: 1000;


//  ------------------------------------------------
//  Others
//  ------------------------------------------------

