//  ================================================
//  Unique styles
//  ================================================

// Information: unique classes start with u-

.u-{

    // ------------------------------------------------
    // Container
    // ------------------------------------------------

    &container {
        position: relative;
        width: 94%;
        max-width: 1180px;
        margin: 0 auto;
    }


    // ------------------------------------------------
    // Button
    // ------------------------------------------------

    &button {

    }


    // ------------------------------------------------
    // clearfix
    // ------------------------------------------------

    &clearfix {
        @extend %clearfix;
    }


    // ------------------------------------------------
    // visible styles
    // ------------------------------------------------

    &hidden {
        display: none !important;
        visibility: hidden;
    }

    &visuallyhidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        &.focusable:active,
        &.focusable:focus {
            clip: auto;
            height: auto;
            margin: 0;
            overflow: visible;
            position: static;
            width: auto;
        }
    }

    &invisible {
        visibility: hidden;
    }


    // ------------------------------------------------
    // alignment
    // ------------------------------------------------

    &l { text-align: left }
    &r { text-align: right }
    &c { text-align: center }


    // ------------------------------------------------
    // float & clear
    // ------------------------------------------------

    &float-left { float: left; }
    &float-right { float: right; }
    &float-none { float: none; }

    &clear { clear: both }


    // ------------------------------------------------
    // Visible/Hidden responsive Styles
    // ------------------------------------------------

    @if ($has-visible-hidden) {

        &hidden-desktop,
        &visible-mobile,
        &visible-tablet {
            display: none!important;
        }

        &visible-desktop { display: block!important }


        @include tablet {
            &hidden-tablet,
            &visible-desktop,
            &visible-mobile {
                display: none!important
            }

            &visible-tablet,
            &hidden-desktop {
                display: block!important
            }
        }

        @include mobile {
            &hidden-mobile,
            &visible-desktop,
            &visible-tablet {
                display: none!important
            }

            &visible-mobile,
            &hidden-desktop,
            &hidden-tablet {
                display: block!important
            }
        }
    }
}
