//  ================================================
//  Typography
//  ================================================


//  ------------------------------------------------
//  Font-face
//  ------------------------------------------------

//@include font-face(Avenir, 'avenir-light', $weight: 100);

@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=b4adedee-923a-4997-93b3-d485fa91686f");

@include font-face(Avenir, 'avenir-next', $weight: normal, $style: normal);
@include font-face(Avenir, 'avenir-next-bold', $weight: bold, $style: normal);

@include font-face(Minion, 'minion', $weight: normal, $style: normal);
@include font-face(Minion, 'minion-italic', $weight: normal, $style: italic);

@include font-face(Minion, 'minion-bold', $weight: bold, $style: normal);
@include font-face(Minion, 'minion-bold-italic', $weight: bold, $style: italic);



/*
    Information: only general font-styles
*/

//  ------------------------------------------------
//  Body
//  ------------------------------------------------

body {
    font-family: Minion, serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-size: 20px;
    line-height: 32px;
    color: $body;
}


//  ------------------------------------------------
//  Headlines
//  ------------------------------------------------

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-family: Avenir, sans-serif;
}

h1 {}

h2 {
    font-size: 18px;
    line-height: 32px;
    color: $highlight;
    text-transform: uppercase;
    margin-bottom: 5px;
}


h3 {
    font-size: 18px;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

h4 {

}

h5 {

}

h6 {

}