//  ================================================
//  Footer
//  ================================================

footer.main {
    background: #000;
    color: #fff;
    font-family: Avenir, sans-serif;
    font-size: 18px;
    line-height: 32px;
    padding: 60px 0 50px;
    position: relative;
    z-index: $z8;

    .u-container {
        border-top: 1px solid #a3a5a6;
        padding-top: 30px;
    }


    .address {

        .address__title {
            text-transform: uppercase;
        }

        p {
            display: inline-block;

            &:first-child {
                margin-right: 130px;
            }
        }
    }
}