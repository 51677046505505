//  ================================================
//  Pages
//  ================================================

main {
    position: relative;
    background: #fff;
    z-index: $z8;

    @include gt-mobile {
        margin-top: calc(80vh);
    }
}

.kontakt {
  margin-top: 0;

}

.map {
    height: 80vh;
    position: relative;
    @include pos(98px 0 null 0, fixed);
    overflow: hidden;

    #map {
        width: 100%;
        height: 100%;
    }
}

.banner {
    height: 80vh;
    position: relative;

    @include gt-mobile {
        @include pos(98px 0 null 0, fixed);
        overflow: hidden;
    }

    @include mobile {
        height: auto;
    }

    &:after {
        content: "";
        @include bg('kaiborisfrank.jpg', $pos: center, $size: cover);
        @include pos(0 0 0 0, $z-index: -1);
        opacity: 0;
        @include tn(opacity);

        @include mobile {
            background-position: top center;
            bottom: auto;
            height: 30vh
        }
    }

    &.is-loaded {
        &:after {
            opacity: 1;
        }
    }

    .u-container {
        height: 100%;
    }

    &__content {
        @include gt-mobile {
            @include pos(center left);
            width: 50%;
            padding-right: 60px;
        }

        @include mobile {
            padding-top: 32vh
        }
    }
}


.intro {
    padding: 100px 0;

    @include mobile {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .u-container {
        overflow: hidden;
    }

    .grid {

    }

    ul {
        padding-left: 17px;
        list-style-type: square;
    }

    @include mobile {
        .col-1-3, .col-2-3 {
            width: 100%;
        }
    }

}

.aside-contact {
    p {
        margin-bottom: 10px;
    }
}