//  ================================================
//  Grid
//  ================================================

@if ($has-grid) {

    $spacing: 60px; // gap width / 2, e.g. 30px gap is 15px $spacing

    $elements: article, li, div, aside;

    $columns: (
    //  size    d-       t-   m-
        "1-2"   50       50   100,
        "1-3"   33.333   50   100,
        "2-3"   66.667   50   100,
        "1-4"   25       50   100,
        "3-4"   75       50   100,
    );


    @function prefixSelector($prefix, $column){
        // e.g. .d-1-3
        @return unquote('.#{$prefix}-#{nth($column, 1)}');
    }

    @function setSelectors($prefix, $column) {
        $selector: ();
        $selector: $selector, prefixSelector('col', $column);

        @each $element in $elements {
            // e.g. .d-1-3 > article
            $selector: $selector, prefixSelector($prefix, $column) unquote('> #{$element}');
        }

        @return $selector;
    }

    .grid {
        padding-left: 0;
        padding-right: 0;
        margin-left: -#{$spacing};
        margin-right: -#{$spacing};
        list-style: none;
        overflow: hidden;
        position: relative;
        //word-spacing: -5px;
        @extend %clearfix;

        @include mobile {
            margin-left: 0;
            margin-right: 0;

            &[class^="m-"], &[class*=" m-"] {
                margin-left: -#{$spacing};
                margin-right: -#{$spacing};
            }
        }

        &.is-list {
            img {
                width: 100%;
                line-height: 0;
            }
        }

        $selector: ();
        @each $element in $elements {
            $selector: $selector, unquote('> #{$element}');
        }

        #{$selector} {
            display: inline-block;
            *display: inline;
            zoom: 1;
            margin-left: 0;
            margin-right: 0;
            position: relative;
            float: left;
            padding: 0 $spacing;

            @include mobile {
                padding-left: 0;
                padding-right: 0;
            }
        }

        @include mobile {
            &[class^="m-"], &[class*=" m-"] {
                #{$selector} {
                    padding-left: $spacing;
                    padding-right: $spacing;
                }
            }
        }

    }

    // Desktop d- styles with responsive steps
    @each $column in $columns{

        #{setSelectors('d', $column)} {
            width: #{nth($column, 2)}%;

            @include tablet {
                // if same value = inherited, so doesn't need here
                @if (nth($column, 2) != nth($column, 3)) {
                    width: #{nth($column, 3)}%;
                }
            }

            @include mobile {
                // if same value = inherited, so doesn't need here
                @if (nth($column, 3) != nth($column, 4)) {
                    width: #{nth($column, 4)}%;
                    margin-bottom: $spacing;
                }
            }
        }
    }

    // Tablet t- styles
    @include tablet {
        @each $column in $columns{
            #{setSelectors('t', $column)} {
                width: #{nth($column, 2)}%;
            }
        }
    }

    // Mobile m- styles
    @include mobile {
        @each $column in $columns{
            #{setSelectors('m', $column)} {
                width: #{nth($column, 2)}%;
                margin-bottom: $spacing;
            }
        }
    }
}
