//  ================================================
//  Icons
//  ================================================ 

@if ($has-icons) {
    
    @include font-face(icons, 'icons', $weight: normal);
  
    %icons {
        position: relative;

        &:before {
            font-family: "icons";
            display: inline-block;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            speak: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    [class^="icon-"], [class*=" icon-"] {
        @extend %icons;
    }

    .icon-arrow:before { content: "\E001"; } 

}